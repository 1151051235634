import React, { useEffect, useMemo, useRef, useState } from 'react';
import DemoFramework from 'utilities/DemoFramework2';
import "styles/bitriddle.scss";

const CANVAS_WIDTH = 1000;
const CANVAS_HEIGHT = 280;
const FPS = 30;

const values = {
    FADE: {
        Min: 0,
        Max: 0.5,
        TransitionFrames: 10
    }
};

const NOR_HINT_COLOR = "#a00";
const AND_HINT_COLOR = "#009";
const FLIP_HINT_COLOR = "#090";
const BIT_BORDER_COLOR = "#999";

let shiftRegisterBits: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0];
let targetBits: Array<number | null> = [1, 1, 1, 1, 1, 1, 1, 1];
let hintColors: Array<string> = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR];
let moveCount = 0;
let totalMoveCount = 0;
let roundMessages = [""];

const BitRiddleIndex = () => {

    const [wonRound, setWonRound] = useState(false);
    const [wonGame, setWonGame] = useState(false);
    const wonGameRef = useRef(wonGame);
    wonGameRef.current = wonGame;
    const [round, setRound] = useState(0);
    const roundRef = useRef(round);
    roundRef.current = round;

    const resetShiftRegisterBits = () => {
        shiftRegisterBits = [0, 0, 0, 0, 0, 0, 0, 0];
        console.log("Reset bits");
    }

    const shiftBitIntoRegister = (bit: number) => {
        shiftRegisterBits.unshift(bit);
        shiftRegisterBits.pop();
        moveCount++;
        totalMoveCount++;
        setWonRound(checkForWin());
    }

    const flipBit = (bit: number) => {
        return !bit ? 1 : 0;
    }

    const andBits = (bit1: number, bit2: number) => {
        return (bit1 && bit2) ? 1 : 0;
    }

    const orBits = (bit1: number, bit2: number) => {
        return (bit1 || bit2) ? 1 : 0;
    }

    const norBits = (bit1: number, bit2: number) => {
        return (!bit1 && !bit2) ? 1 : 0;
    }

    const nandBits = (bit1: number, bit2: number) => {
        return !(!bit1 && !bit2) ? 1 : 0;
    }

    const checkForWin = () => {
        const finalBits = [];
        let allCorrect = true;

        shiftRegisterBits.forEach(
            (bit, index, arr) => {
                if (targetBits[index] !== null && getModifiedBit(index) !== targetBits[index]) {
                    allCorrect = false;
                }
            });

        return allCorrect;
    }

    const winRound = () => {
        const newRound = roundRef.current + 1;
        setRound(newRound);
        moveCount = 0;
        resetShiftRegisterBits();
        console.log("Round won!");
        console.log("Starting round " + round);
        setupRoundBits(newRound);
        setWonRound(false);

        if (newRound === 12) {
            setWonGame(true);
        }
    }

    const resetGame = (targetRound: number = 0) => {
        roundMessages = [""];
        setRound(targetRound);
        moveCount = 0;
        totalMoveCount = 0;
        resetShiftRegisterBits();
        setupRoundBits(targetRound);
        console.log("Reset game!");
        setWonRound(false);
        setWonGame(false);
    }

    const skipTutorial = () => {
        resetGame(10);
    }

    const setupRoundBits = (roundIndex: number) => {
        if (roundIndex === 0) {
            targetBits = [1, 1, 1, 1, 1, 1, 1, 1];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR];
            roundMessages = [
                "Click '1' 8 times to turn on all 8 bits.",
                "A value of '1' turns on a bit, '0' turns it off.",
                "Each time you click a '1' or '0', you're pushing it into the",
                "first bit bumping the last off the end."
            ];
        }
        else if (roundIndex === 1) {
            targetBits = [0, 0, 0, 0, 1, 1, 1, 1];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR];
            roundMessages = [
                "Click '1' 4 times and '0' 4 times to set 4 bits off",
                "and 4 bits on."
            ];
        }
        else if (roundIndex === 2) {
            targetBits = [null, null, null, 1, 0, null, null, null];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR];
            roundMessages = [
                "Notice how not all bits have a target state.",
                "If you don't see a target in the center it means",
                "that bit can have any value."
            ];
        }
        else if (roundIndex === 3) {
            targetBits = [1, 1, 1, null, null, 1, 1, 1];
            hintColors = [BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR];
            roundMessages = [
                "The second bit is whatever value you put into it, but flipped.",
                "Try to fill all 8 bits while leaving the second space off.",
                "Winning is about matching the bits final state to it's target"
            ];
        }
        else if (roundIndex === 4) {
            targetBits = [1, 1, 1, 1, 1, 1, 1, 1];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR];
            roundMessages = [
                "Now try with 2 flipped bits."
            ];
        }
        else if (roundIndex === 5) {
            targetBits = [null, 0, null, 1, 1, null, 0, null];
            // todo: change this to a flip other bit tutorial
            hintColors = [BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR];
            roundMessages = [
                "Here, the 2 flipped bits are based on other bits.",
                "Tricky!!"
            ];
        }
        else if (roundIndex === 6) {
            targetBits = [0, 1, 1, 1, 1, 1, 1, 0];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, FLIP_HINT_COLOR, AND_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR];
            roundMessages = [
                "The 5th bit only turns on when 2 other bits ARE",
                "ON. This is an AND bit.",
                "Oh, and there are a few flipped bits as well."
            ];
        }
        else if (roundIndex === 7) {
            targetBits = [0, 0, 0, 0, 1, 1, 1, 1];
            hintColors = [BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, NOR_HINT_COLOR];
            roundMessages = [
                "The 8th bit only turns on when 2 other bits",
                "are NOT ON. This is a NOR bit.",
                "Plus some flipped bits, why not."
            ];
        }
        else if (roundIndex === 8) {
            targetBits = [null, 1, 0, 1, null, null, 1, 1];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, AND_HINT_COLOR, NOR_HINT_COLOR];
            roundMessages = [
                "Alright, bringing it all together now.",
                "This round has alittle of everything!"
            ];
        }
        else if (roundIndex === 9) {
            targetBits = [0, null, 1, 0, null, 1, null, 1];
            hintColors = [FLIP_HINT_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, NOR_HINT_COLOR, BIT_BORDER_COLOR, AND_HINT_COLOR];
            roundMessages = [
                "Ohhhh, final training stage.",
                "Good luck!"
            ];
        }
        else if (roundIndex === 10) {
            targetBits = [1, null, 0, null, 1, null, 0, null];
            hintColors = [FLIP_HINT_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR, FLIP_HINT_COLOR, BIT_BORDER_COLOR,];
            roundMessages = [
                ""
            ];
        }
        else if (roundIndex === 11) {
            targetBits = [null, null, null, 1, null, 1, null, 1];
            hintColors = [BIT_BORDER_COLOR, BIT_BORDER_COLOR, BIT_BORDER_COLOR, NOR_HINT_COLOR, BIT_BORDER_COLOR, NOR_HINT_COLOR, BIT_BORDER_COLOR, AND_HINT_COLOR,];
            roundMessages = [
                ""
            ];
        }
    }

    const getModifiedBit = (currentIndex: number) => {
        let val = shiftRegisterBits[currentIndex];

        if (roundRef.current === 0) {
            // LEVEL 1 //////////////////////////////////////////////////////////////////////
        }
        else if (roundRef.current === 1) {
            // LEVEL 2 //////////////////////////////////////////////////////////////////////
        }
        else if (roundRef.current === 2) {
            // LEVEL 3 //////////////////////////////////////////////////////////////////////
        }
        else if (roundRef.current === 3) {
            // LEVEL 4 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 1) {
                val = flipBit(val);
            }
        }
        else if (roundRef.current === 4) {
            // LEVEL 5 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 3) {
                val = flipBit(val);
            }
            else if (currentIndex === 7) {
                val = flipBit(val);
            }
        }
        else if (roundRef.current === 5) {
            // LEVEL 6 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 1) {
                val = flipBit(getModifiedBit(4));
            }
            else if (currentIndex === 6) {
                val = flipBit(getModifiedBit(3));
            }
        }
        else if (roundRef.current === 6) {
            // LEVEL 7 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 4) {
                val = andBits(getModifiedBit(2), getModifiedBit(6));
            }
            else if (currentIndex === 2) {
                val = flipBit(val);
            }
            else if (currentIndex === 3) {
                val = flipBit(val);
            }
        }
        else if (roundRef.current === 7) {
            // LEVEL 8 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 7) {
                val = norBits(getModifiedBit(1), getModifiedBit(3));
            }
            else if (currentIndex === 1) {
                val = flipBit(val);
            }
            else if (currentIndex === 3) {
                val = flipBit(val);
            }
        }
        else if (roundRef.current === 8) {
            // LEVEL 9 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 6) {
                val = andBits(getModifiedBit(1), getModifiedBit(3));
            }
            if (currentIndex === 7) {
                val = norBits(getModifiedBit(2), getModifiedBit(0));
            }
            if (currentIndex === 2) {
                val = flipBit(val);
            }
        }
        else if (roundRef.current === 9) {
            // LEVEL 10 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 0) {
                val = flipBit(getModifiedBit(1));
            }
            else if (currentIndex === 5) {
                val = norBits(getModifiedBit(0), getModifiedBit(4));
            }
            else if (currentIndex === 7) {
                val = andBits(getModifiedBit(5), getModifiedBit(6));
            }
        }
        else if (roundRef.current === 10) {
            // LEVEL 11 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 0) {
                val = flipBit(getModifiedBit(1));
            }
            if (currentIndex === 2) {
                val = flipBit(getModifiedBit(3));
            }
            if (currentIndex === 4) {
                val = flipBit(getModifiedBit(5));
            }
            if (currentIndex === 6) {
                val = flipBit(getModifiedBit(7));
            }
        }
        else if (roundRef.current === 11) {
            // LEVEL 12 //////////////////////////////////////////////////////////////////////
            if (currentIndex === 3) {
                val = norBits(getModifiedBit(6), getModifiedBit(0));
            }
            else if (currentIndex === 5) {
                val = norBits(getModifiedBit(2), getModifiedBit(1));
            }
            else if (currentIndex === 7) {
                val = andBits(getModifiedBit(4), getModifiedBit(5));
            }
        }

        return val;
    }

    const init =
        async (p: any) => {
            console.log("Init");
            p.ctx.fillStyle = "white";
            p.ctx.strokeStyle = "white";
            p.ctx.lineWidth = 5;
            p.ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        };

    const update =
        (p: any) => {
            p.ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

            if (wonGameRef.current) {
                p.ctx.font = '90px arial';
                p.ctx.fillText(
                    `Congrats!`,
                    300,
                    100);

                p.ctx.font = '40px arial';
                p.ctx.fillText(
                    "You've beaten all available rounds!",
                    180,
                    160);

                p.ctx.font = '90px arial';
                p.ctx.fillText(
                    `Move Total: ${totalMoveCount}`,
                    230,
                    250);
                return;
            }

            shiftRegisterBits.forEach(
                (bit, index) => {
                    p.ctx.strokeStyle = BIT_BORDER_COLOR;

                    if (roundRef.current < 10) {
                        // actual circle
                        p.ctx.fillStyle = bit ? "#fff" : "#000";
                        p.ctx.beginPath();
                        p.ctx.arc(
                            72 + (index * 120),
                            72,
                            45,
                            0,
                            2 * Math.PI);
                        p.ctx.fill();
                        p.ctx.stroke();
                    }

                    // final circle
                    p.ctx.fillStyle = getModifiedBit(index) ? "#fff" : "#000";
                    p.ctx.beginPath();
                    p.ctx.arc(
                        80 + (index * 120),
                        80,
                        45,
                        0,
                        2 * Math.PI);
                    p.ctx.fill();
                    p.ctx.stroke();
                });

            targetBits.forEach(
                (bit, index) => {
                    if (bit === null) {
                        return;
                    }

                    p.ctx.strokeStyle = hintColors[index];
                    p.ctx.fillStyle = bit ? "#fff" : "#000";
                    p.ctx.beginPath();
                    p.ctx.arc(
                        80 + (index * 120),
                        80,
                        12,
                        0,
                        2 * Math.PI);
                    p.ctx.fill();
                    p.ctx.stroke();
                });

            p.ctx.font = '50px arial';
            p.ctx.fillStyle = "#fff";
            p.ctx.fillText(
                `Round: ${roundRef.current}`,
                10,
                175);

            p.ctx.fillText(
                `Moves: ${moveCount}`,
                10,
                220);

            p.ctx.fillText(
                `Total: ${totalMoveCount}`,
                10,
                265);

            p.ctx.font = '24px arial';
            roundMessages.forEach(
                (message, index) => {
                    p.ctx.fillText(
                        message,
                        270,
                        175 + (index * 27));
                });
        };

    const keyUp = (p: any, code: any) => {
        //console.log("Key press ", code);

        if (code === "Space") {
            shiftBitIntoRegister(1);
        }
        else if (code === "ArrowRight") {
            shiftBitIntoRegister(0);
        }
        else if (code === "KeyC") {
            resetShiftRegisterBits();
        }
        else if (code === "Enter") {
            checkForWin();
        }
        else if (code === "Escape") {
            resetGame();
        }
    };

    useEffect(
        () => {
            new DemoFramework(
                {
                    fps: FPS,
                    width: CANVAS_WIDTH,
                    height: CANVAS_HEIGHT,
                    values,
                    init,
                    update,
                    keyUp
                });

            resetGame();
        }, []);

    return (
        <>
            <canvas id="canvas" width="800" height="600"></canvas>
            {
                !wonGame &&
                <>
                    <div className="actionContainer">
                        <button id="bit1" className="action" onClick={() => shiftBitIntoRegister(1)}>1</button>
                        {
                            wonRound &&
                            <button id="winRound" className="action winRound" onClick={() => winRound()}>Complete!</button>
                        }
                        {
                            !wonRound &&
                            <>
                                <button id="clear" className="action" onClick={() => resetShiftRegisterBits()}>Clear</button>
                                <button id="reset" className="action" onClick={() => resetGame()}>Restart Game</button>
                            </>
                        }
                        <button id="bit0" className="action" onClick={() => shiftBitIntoRegister(0)}>0</button>
                    </div>
                    {
                        round <= 9 &&
                        <button id="skip" className="action skipTutorial" onClick={() => skipTutorial()}>Skip Tutorial</button>
                    }
                    <div className="help">
                        <div>The big circle is the final bit state</div>
                        <div>The circle behind that is the actual bit state</div>
                        <div>The small circle inside it is the target state and hint</div>
                        <div>Empty target means it should be off</div>
                        <div>Filled target means it should be on</div>
                        <div>No target means it doesn't matter what state it's in</div>
                        <div style={{ backgroundColor: FLIP_HINT_COLOR }}>Green target border means it's final state is the opposite of whatever you put there, or somewhere else</div>
                        <div style={{ backgroundColor: AND_HINT_COLOR }}>Blue target border means it only turns on when 2 other spots are on</div>
                        <div style={{ backgroundColor: NOR_HINT_COLOR }}>Red target border means it only turns on when 2 other spots are off</div>
                    </div>
                </>
            }
        </>
    );
}

export default BitRiddleIndex;