import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from "components/GlobalContext";
import { PostType } from "models/IPost";
import { useLocation } from "react-router-dom";

library.add(fas);

const SCROLL_DEBOUNCE_TIME = 10;
const VISIBLE_SCROLL_HEIGHT = 240;

const BottomMenu = () => {
    const [inView, setInView] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [hide, setHide] = useState(false);
    let location = useLocation();

    useEffect(() => {
        if (location.pathname !== "/") {
            setHide(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        let debounceId = 0;

        const calcVisibility = () => {
            if (window.scrollY > VISIBLE_SCROLL_HEIGHT) {
                if (!inView) {
                    setInView(true);
                }
            }
            else {
                if (inView) {
                    setInView(false);
                }
            }
        }

        const scrollHandler = () => {
            clearTimeout(debounceId);
            debounceId = window.setTimeout(calcVisibility, SCROLL_DEBOUNCE_TIME);
        };

        window.addEventListener("scroll", scrollHandler);
        window.addEventListener("resize", scrollHandler);
        scrollHandler();
        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("resize", scrollHandler);
        }
    });

    const changeFilter = (type?: PostType) => {
        if (globalContext.postFilter === type) {
            globalContext.setPostFilter(undefined);

        } else {
            globalContext.setPostFilter(type);
        }
    }

    if (hide) {
        return (<></>);
    }

    return (
        <div className={`bottomMenu ${inView ? "showing" : ""}`}>
            <span className="bottomMenuLabel">Filter: </span>
            <button
                aria-label="Only show music"
                className={`${globalContext.postFilter === PostType.Music ? "active" : ""}`}
                onClick={() => { changeFilter(PostType.Music) }}><FontAwesomeIcon icon="music" /></button>
            <button
                aria-label="Only show articles"
                className={`${globalContext.postFilter === PostType.Writing ? "active" : ""}`}
                onClick={() => { changeFilter(PostType.Writing) }}><FontAwesomeIcon icon="pencil-alt" /></button>
            <button
                aria-label="Only show videos"
                className={`${globalContext.postFilter === PostType.Video ? "active" : ""}`}
                onClick={() => { changeFilter(PostType.Video) }}><FontAwesomeIcon icon="video" /></button>
            <button
                aria-label="Only show photography"
                className={`${globalContext.postFilter === PostType.Photo ? "active" : ""}`}
                onClick={() => { changeFilter(PostType.Photo) }}><FontAwesomeIcon icon="camera" /></button>
            <button
                aria-label="Only show code"
                className={`${globalContext.postFilter === PostType.Code ? "active" : ""}`}
                onClick={() => { changeFilter(PostType.Code) }}><FontAwesomeIcon icon="laptop-code" /></button>
        </ div>);
}

export default BottomMenu;