import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from "components/GlobalContext";
import { PostType } from "models/IPost";
import { useLocation } from "react-router-dom";

library.add(fas);

const Menu = () => {
    const globalContext = useContext(GlobalContext);
    const location = useLocation();
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (location.pathname !== "/") {
            setHide(true);
        }
    }, [location.pathname]);

    const changeFilter = (type?: PostType) => {
        if (globalContext.postFilter === type) {
            globalContext.setPostFilter(undefined);
        }
        else {
            globalContext.setPostFilter(type);
        }
    }

    const youtubeSubs =
        globalContext.externalData && globalContext.externalData.length > 1 ?
            parseInt(globalContext.externalData[1].data.split(",")[0].split(":")[1]).toLocaleString() :
            null;

    const youtubeViews =
        globalContext.externalData && globalContext.externalData.length > 1 ?
            parseInt(globalContext.externalData[1].data.split(",")[1].split(":")[1]).toLocaleString() :
            null;

    const githubFollowers =
        globalContext.externalData && globalContext.externalData.length > 1 ?
            parseInt(globalContext.externalData[0].data.split(",")[2].split(":")[1]).toLocaleString() :
            null;

    const githubRepos =
        globalContext.externalData && globalContext.externalData.length > 1 ?
            parseInt(globalContext.externalData[0].data.split(",")[0].split(":")[1]).toLocaleString() :
            null;

    if (hide) {
        return (
            <a href="/">GO BACK</a>
        );
    }

    return (
        <div className="mainMenu">
            <div className="aboutPhoto">
                <img src="/images/aboutphoto.jpg" alt="" />
            </div>
            <div className="aboutContent">
                <h1>Hi, I'm Jeff!</h1>
                I'm an enthusiastic software engineer, inventor and artist.
                This is a portfolio of some of my software, projects, articles, photography, music, and videos<br /><br />

                {
                    youtubeSubs &&
                    <>
                        <a
                            className="externalLink"
                            href="https://www.youtube.com/artsyengineering"
                            rel="noopener noreferrer"
                            target="_blank">Youtube</a> Subs: {youtubeSubs} Views: {youtubeViews}<br />
                    </>
                }
                {
                    githubFollowers &&
                    <>
                        <a
                            className="externalLink"
                            href="https://github.com/jgoergen/"
                            rel="noopener noreferrer"
                            target="_blank">Github</a> Follows: {githubFollowers} Repos: {githubRepos}<br />
                    </>
                }

                <div className="filters">
                    Filter:
                    <button
                        aria-label="Only show music"
                        className={`${globalContext.postFilter === PostType.Music ? "active" : ""}`}
                        onClick={() => { changeFilter(PostType.Music) }}><FontAwesomeIcon icon="music" />
                    </button>
                    <button
                        aria-label="Only show articles"
                        className={`${globalContext.postFilter === PostType.Writing ? "active" : ""}`}
                        onClick={() => { changeFilter(PostType.Writing) }}><FontAwesomeIcon icon="pencil-alt" />
                    </button>
                    <button
                        aria-label="Only show videos"
                        className={`${globalContext.postFilter === PostType.Video ? "active" : ""}`}
                        onClick={() => { changeFilter(PostType.Video) }}><FontAwesomeIcon icon="video" />
                    </button>
                    <button
                        aria-label="Only show photos"
                        className={`${globalContext.postFilter === PostType.Photo ? "active" : ""}`}
                        onClick={() => { changeFilter(PostType.Photo) }}><FontAwesomeIcon icon="camera" />
                    </button>
                    <button
                        aria-label="Only show code"
                        className={`${globalContext.postFilter === PostType.Code ? "active" : ""}`}
                        onClick={() => { changeFilter(PostType.Code) }}><FontAwesomeIcon icon="laptop-code" />
                    </button>
                </div>
            </div>
        </div>);
}

export default Menu;