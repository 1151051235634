export default interface IPost {
    title: string
    description: string;
    url: string;
    imageUrl: string;
    type: PostType;
    created: string;
    active: boolean;
}

export interface IPostSubmission {
    title: string
    description: string;
    url: string;
    imageUrl: string;
    type: string;
    created: string;
    active: boolean;
}

export enum PostType {
    Photo = "Photo",
    Video = "Video",
    Writing = "Writing",
    Music = "Music",
    Code = "Code"
} 