import React, { useState, useEffect, useContext, useRef } from 'react';
import IPostCard from 'models/IPostCard';
import PostCard from 'components/PostCard';
import { GlobalContext } from 'components/GlobalContext';

const BORDER_CLEARENCE = 10;
const WAY_OUT_BORDER_CLEARENCE = 1000;
const SCROLL_DEBOUNCE_TIME = 5;

const HomeIndex = () => {
    const globalContext = useContext(GlobalContext);
    const [cards, setCards] = useState<IPostCard[]>();
    const [chosenId, setChosenId] = useState(-1);
    const cardsRef = useRef<IPostCard[]>();
    cardsRef.current = cards;
    let scrollDebounceID = 0;

    const scrollHandler = () => {
        clearTimeout(scrollDebounceID);
        scrollDebounceID = window.setTimeout(
            () => {
                if (cardsRef.current) {
                    cardsRef.current.forEach(card => [card.inView, card.wayOut] = checkIfInView(card));
                    setCards([...cardsRef.current]);
                }
            },
            SCROLL_DEBOUNCE_TIME);
    };

    useEffect(
        () => {
            if (globalContext && globalContext.posts) {
                (async () => {
                    setCards(
                        // @ts-ignore
                        globalContext.posts
                            .filter(post => post.active)
                            .map(
                                (post, index) => {
                                    return {
                                        title: post.title,
                                        description: post.description,
                                        url: post.url,
                                        imageUrl: post.imageUrl,
                                        type: post.type,
                                        created: post.created,
                                        id: index,
                                        ready: false,
                                        inView: false,
                                        wayOut: true,
                                        hidden: false
                                    };
                                }));

                    scrollHandler();
                })();
            }
        },
        [globalContext, globalContext.posts]);

    useEffect(
        () => {
            if (cardsRef.current) {
                cardsRef.current.forEach(
                    card => {
                        card.hidden =
                            globalContext.postFilter !== undefined ?
                                (card.type !== globalContext.postFilter) :
                                false;
                    }
                );
                setCards([...cardsRef.current]);
                scrollHandler();
            }
        },
        [globalContext.postFilter]);

    const checkIfInView = (card: IPostCard) => {
        if (card.ref && card.ref.current) {
            // @ts-ignore
            const rect = card.ref.current.getBoundingClientRect();
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const inView = rect.bottom > BORDER_CLEARENCE && rect.top < (vh - BORDER_CLEARENCE);
            const wayOut = rect.bottom < (-WAY_OUT_BORDER_CLEARENCE) || rect.top > (vh + WAY_OUT_BORDER_CLEARENCE);
            return [inView, wayOut];
        }

        return [false, false];
    };

    useEffect(
        () => {
            window.addEventListener("scroll", scrollHandler);
            window.addEventListener("resize", scrollHandler);
            scrollHandler();
            return () => {
                window.removeEventListener("scroll", scrollHandler);
                window.removeEventListener("resize", scrollHandler);
            }
        }, []);

    const registerRef = (ref: any, id: number) => {
        if (ref.current) {
            const card = cards?.find(c => c.id === id);
            if (card) {
                card.ref = ref;
            }
        }
    };

    return (
        <div className="postCardContainer">
            {
                cards?.map(
                    card =>
                        <PostCard
                            key={card.id}
                            card={card}
                            chosenId={chosenId}
                            setChosenId={setChosenId}
                            registerRef={registerRef} />
                )
            }
        </div>
    );
}

export default HomeIndex;