import React from 'react';

const starGraphics = [".", ",", ".", "*", "+", "'"];

const StarField = () => {
    const stars = [];
    const numberOfStars =
        Math.min(
            ((window.innerHeight || 1000) * (window.innerWidth || 800)) / 40000,
            150);

    for (var i = 0; i < numberOfStars; i++) {

        stars.push({
            x: Math.random() * 100,
            y: Math.random() * 100,
            speed: Math.random() * 5,
            graphic: starGraphics[Math.floor(Math.random() * starGraphics.length)],
            spaces: Array.from({ length: Math.floor(Math.random() * 5) }).map((i, index) => <span key={index}>&nbsp;</span>)
        });
    }

    return (
        <>
            {stars.map(
                (star, index) => (
                    <span
                        key={index}
                        className={`star`}
                        style={{
                            top: `${star.x}%`,
                            left: `${star.y}%`,
                            animationDelay: `${star.speed}s`
                        }}>{star.spaces}{star.graphic}</span>
                )
            )}
        </>
    );
}

export default StarField;