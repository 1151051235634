import React, { useState, FC, createContext, useEffect } from "react";
import { PostType } from "models/IPost";
import { getExternalData, getPosts } from 'services/apiservice';
import IPost from 'models/IPost';
import moment from 'moment';
import IExternalData from "models/IExternalData";

interface IGlobalContextProps {
    postFilter?: PostType;
    setPostFilter: (type?: PostType) => void;
    posts?: IPost[];
    externalData?: IExternalData[];
    loaderShowing: boolean;
}

export const GlobalContext = createContext<IGlobalContextProps>({
    setPostFilter: () => { },
    loaderShowing: true
});

const PRELOAD_BEFORE_SHOWING_COUNT = 10;

const GlobalContextWrapper: FC = props => {
    const [postFilter, setPostFilter] = useState<PostType>();
    const [loading, setLoading] = useState(true);
    const [loaderShowing, setLoaderShowing] = useState(true);
    const [posts, setPosts] = useState<IPost[]>([]);
    const [externalData, setExternalData] = useState<IExternalData[]>([]);

    useEffect(
        () => {
            setLoading(true);
            setLoaderShowing(true);

            let imagesLoaded = 0;
            const imageLoadedCallback = (index: number, postCount: number) => {
                imagesLoaded++;

                if (imagesLoaded >= PRELOAD_BEFORE_SHOWING_COUNT) {
                    window.setTimeout(
                        () => {
                            setLoading(false);
                            window.setTimeout(
                                () => {
                                    setLoaderShowing(false);
                                },
                                500);
                        },
                        500);
                }
            };

            (async () => {

                const postResult = await getPosts(0, 300);
                postResult
                    .sort((a, b) => moment(b.created.split("T")[0]).toDate().getTime() - moment(a.created.split("T")[0]).toDate().getTime())
                    .forEach(
                        (post, index, array) => {
                            const preload = new Image();
                            preload.onload = () => { imageLoadedCallback(index, array.length); };
                            preload.src = post.imageUrl;
                        });

                setPosts(postResult);
            })();
        },
        []);

    useEffect(
        () => {
            (async () => {
                setExternalData(await getExternalData());
            })();
        },
        [posts]);

    if (loaderShowing) {
        return <div className={`siteLoader ${loading ? "fadeIn" : "fadeOut"}`}>
            <span>l</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </div>
    }

    return (
        <GlobalContext.Provider value={{ posts, postFilter, setPostFilter, externalData, loaderShowing }}>
            {props.children && props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextWrapper;