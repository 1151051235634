export async function fetchPostAsync<T>(url: string, data?: any, options?: any, asJson: boolean = true): Promise<T> {
    return await new Promise<T>((resolve, reject) => {
        fetch(
            url,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                redirect: "follow",
                referrer: "no-referrer",
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data ? JSON.stringify(data) : undefined,
                ...options
            })
            .then(response => resolve(asJson ? response.json() : response.text()))
            .catch(error => reject(error));
    });
}

export async function fetchGetAsync<T>(url: string, data?: any, options?: any, asJson: boolean = true): Promise<T> {

    const parameters = objectToGetParameters(data);
    const index = url.indexOf("?");

    if (index === -1) {
        url = `${url}?${parameters}`;
    }
    else if (index === url.length - 1) {
        url = `${url}${parameters}`;
    }
    else {
        url = `${url}&${parameters}`;
    }

    return await new Promise<T>((resolve, reject) => {
        fetch(
            url,
            {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                redirect: "error",
                referrer: "no-referrer",
                credentials: 'include',
                ...options
            })
            .then(response => resolve(asJson ? response.json() : response.text()))
            .catch(error => reject(error));
    });
}

export function objectToGetParameters(source: any, includeEmptyValues: boolean = false) {
    return (
        Object
            .keys(source)
            .filter(
                k => ((source[k] && source[k].length > 0) || source[k] === false || source[k] === 0) || includeEmptyValues)
            .map(
                (key) => {
                    if (Array.isArray(source[key])) {
                        const arrayParts = [];
                        for (let i = 0; i < source[key].length; i++)
                            arrayParts.push(
                                encodeURIComponent(key + '[]') + '=' + encodeURIComponent(source[key][i]));
                        return arrayParts.join('&');
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(source[key]);
                })
            .join('&'));
}