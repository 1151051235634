import 'styles/App.scss';

import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomeIndex from './home/Index';
import AboutIndex from './about/Index';
import GlobalContextWrapper from 'components/GlobalContext';
import Menu from './Menu';
import { Container } from 'react-bootstrap';
import BottomMenu from './BottomMenu';
import StarField from 'components/StarField';
import BitRiddleIndex from './blitriddle/Index';
//import PointilizerIndex from './pointilizer/Index';
//import PointilizerCustom from './pointilizer/Custom';

const App = () => {
	return (
		<>
			<StarField />
			<GlobalContextWrapper>
				<AppContent />
			</GlobalContextWrapper>
		</>
	);
}

const AppContent: React.FC = () => {
	return (
		<>
			<BrowserRouter>
				<Menu />
				<Container fluid role="main">
					<Routes>
						<Route path="/about" element={<AboutIndex />} />
						<Route path="/bitriddle" element={<BitRiddleIndex />} />
						{/*<Route path="/pointilizer" element={<PointilizerIndex />} />*/}
						{/*<Route path="/pointilizer/custom" element={<PointilizerCustom />} />*/}
						{/*<Route path="/post" element={<Post />} />*/}
						<Route path="*" element={<HomeIndex />} />
					</Routes>
				</Container>
				<BottomMenu />
			</BrowserRouter>
		</>
	)
}

export default App;