import React from 'react';


const AboutIndex = () => {

    return (
        <>
            <h1>About</h1>
        </>
    );
}

export default AboutIndex;