import React, { useRef, useEffect, useMemo } from "react";
import IPostCard from "models/IPostCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { PostType } from "models/IPost";
library.add(fas);

interface IPostCardProps {
    card: IPostCard;
    chosenId: number;
    setChosenId: (param: number) => void;
    registerRef: (ref: any, id: number) => void;
}

const PostCard: React.FC<IPostCardProps> = (props: IPostCardProps) => {
    const imageRef = useRef(null);

    useEffect(
        () => {
            if (imageRef) {
                props.registerRef(imageRef, props.card.id);
            }
        },
        [props]);

    const icon =
        props.card.type === PostType.Photo ? "camera" :
            props.card.type === PostType.Video ? "video" :
                props.card.type === PostType.Writing ? "pencil-alt" :
                    props.card.type === PostType.Music ? "music" :
                        props.card.type === PostType.Code ? "laptop-code" :
                            "camera";
    /*
        const postPhrase =
            props.card.type === PostType.Photo ? "PHOTO" :
                props.card.type === PostType.Video ? "VIDEO" :
                    props.card.type === PostType.Writing ? "ARTICLE" :
                        props.card.type === PostType.Music ? "MUSIC" :
                            props.card.type === PostType.Code ? "CODE" :
                                "PHOTO";
    */
    const selectPost = (postId: number) => {
        props.setChosenId(postId);
        setTimeout(() => {
            const a = document.createElement("a");
            a.href = props.card.url;
            a.target = "_blank";
            a.click();
            props.setChosenId(-1);
        }, 500);
    };

    const shortenedTitle = props.card.title.length < 18 ? props.card.title : `${props.card.title.substr(0, 18)}...`;

    return useMemo(() => {
        return (
            <div
                key={props.card.id}
                className={`postCard speed${Math.ceil(Math.random() * 5)} ${props.card.hidden ? " hidden" : ""}${props.card.inView && (props.chosenId === -1 || props.chosenId === props.card.id) ? " showing" : ""}`}
                ref={imageRef}
                onClick={() => selectPost(props.card.id)}>
                {
                    !props.card.wayOut &&
                    <>
                        <span className="title"><FontAwesomeIcon icon={icon} /> {shortenedTitle}</span>
                        <div className={`content ${icon}`}>
                            <a href={props.card.url} target="_blank" rel="noopener noreferrer" title={props.card.description}>
                                <img src={props.card.imageUrl} alt="" width="250" height="250" />
                            </a>
                        </div>
                        <div className="detail">
                            <div className="container">
                                <h2>{props.card.title!}</h2>
                                <p>{props.card.description}</p>
                            </div>
                        </div>
                    </>
                }
            </div >
        )
    }, [
        props.chosenId, props.card.wayOut, props.card.inView, props.card.hidden, props.card.description,
        props.card.id, props.card.imageUrl, props.card.title, props.card.url]);
}

export default PostCard;