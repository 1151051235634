import { fetchPostAsync } from "../utilities/fetch";
import IAPIResult from "../models/IAPIResult";
import IPost, { IPostSubmission } from "models/IPost";
import IExternalData from "models/IExternalData";

export async function test(): Promise<string> {
    const result: IAPIResult<string> = await fetchPostAsync<IAPIResult<string>>(
        "/api/test",
        {}
    );
    return result.data;
}

export async function getPosts(start: number, count: number): Promise<IPost[]> {
    const result: IAPIResult<IPost[]> = await fetchPostAsync<IAPIResult<IPost[]>>(
        "/api/getPosts",
        {
            start,
            count
        }
    );
    return result.data;
}

export async function createOrUpdatePost(post: IPostSubmission): Promise<boolean> {
    const result: IAPIResult<boolean> = await fetchPostAsync<IAPIResult<boolean>>(
        "/api/createOrUpdatePost",
        { ...post }
    );
    return result.data;
}

export async function deletePost(post: IPostSubmission): Promise<boolean> {
    const result: IAPIResult<boolean> = await fetchPostAsync<IAPIResult<boolean>>(
        "/api/deletePost",
        { ...post }
    );
    return result.data;
}

export async function getExternalData(): Promise<IExternalData[]> {
    const result: IAPIResult<IExternalData[]> = await fetchPostAsync<IAPIResult<IExternalData[]>>(
        "/api/externalData",
        {}
    );
    return result.data;
}